import stella from './sstesla.webp';
import './App.css';

function App() {
  return (
    <div className="App">
      <header className="App-header">
        <img src={stella} className="App-logo" alt="logo" />
        <p>
          welcome to divergent smp
        </p>
      </header>
    </div>
  );
}

export default App;
